@font-face {
  font-family: 'recoleta-regular';
  src: url('../fonts/recoleta-regular-webfont.woff2') format('woff2'),
       url('../fonts/recoleta-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'recoleta-semibold';
  src: url('../fonts/recoleta-semibold-webfont.woff2') format('woff2'),
       url('../fonts/recoleta-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/ibm-plex-mono-v15-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/ibm-plex-mono-v15-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/ibm-plex-mono-v15-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/ibm-plex-mono-v15-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/ibm-plex-mono-v15-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/ibm-plex-mono-v15-latin-500.svg#IBMPlexMono') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/karla-v23-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/karla-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/karla-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/karla-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/karla-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/karla-v23-latin-regular.svg#Karla') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/karla-v23-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/karla-v23-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/karla-v23-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/karla-v23-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/karla-v23-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/karla-v23-latin-600.svg#Karla') format('svg'); /* Legacy iOS */
}

  $font-family-sans-serif:      'Karla', sans-serif;
  $font-family-monospace:       'IBM Plex Mono', monospace;
  $headings-font-family:        'recoleta-semibold', serif;
  $headings-line-height:        1;
  $headings-margin-bottom:      2.5rem;

  $font-size-base: 1.25rem;
  $line-height-base: 1.5;
  $line-height-sm: 1.25;
  $line-height-lg: 2;

  $h1-font-size: $font-size-base * 5;
  $h2-font-size: $font-size-base * 3;
  $h3-font-size: $font-size-base * 2;
  $h4-font-size: $font-size-base * 1.4;

  $lead-font-size:  1.5rem;
  $lead-font-weight: 600;

  p:not(.font-monospace) {
    a {
      font-weight: 600;
    }
  }

  .font-monospace.smaller {
    font-size: 1rem;
  }
  
  .topline {
    display: block;
    font-family: $font-family-monospace;
    font-weight: 500;
    font-size: calc(1rem + 0.3vw);
    line-height: $line-height-base * 1.5;
    margin-bottom: .75rem;
  }

  /* Button Styles */

  $input-btn-font-family:  $headings-font-family;
  $input-btn-font-size:  1.5rem;
  $input-btn-line-height: 1;
  $input-btn-padding-y: 1.125rem;
  $input-btn-padding-x: 1.35rem;

  .btn-dark.btn-black {
    background-color: $black;
  }

  .btn-beige {
    background-color: $secondary;
    &:hover {
      background-color: $gray-900;
    }
  }

  /* Link Styles */

  a {
    &.link-black {
      color: $black;
      transition: color .2s ease-in-out;
      &:hover {
        color: $primary;
        transition: color .2s ease-in-out;
      }
    }
    &.link-green {
      color: $green;
      transition: color .2s ease-in-out;
      &:hover {
        color: $green-800;
        transition: color .2s ease-in-out;
      }
    }
  }