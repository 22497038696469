/* Bootstrap Color Definitions */
$white:    #fff;
$gray-100: #f7f7f7;
$gray-200: #e7e7e7;
$gray-300: #d7d7d7;
$gray-400: #c7c7c7;
$gray-500: #a7a7a7;
$gray-600: #979797;
$gray-700: #878787;
$gray-800: #686868;
$gray-900: #4E4E4E;
$black:    #1A1D1F;

$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #C3BC0E;
$teal:    #20c997;
$cyan:    #0dcaf0;

$green-100: #f3ed58;
$green-200: #f3ed58;
$green-300: #f3ed58;
$green-400: #f0e829;
$green-500: $green;
$green-600: #d6ce0f;
$green-700: #a7a10c;
$green-800: #7C7933;
$green-900: #646107;

$primary:       $green-800;
$secondary:     #EBEAE4;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         #EBEAE4;
$dark:          $gray-900;


/* Body Styles */
$body-bg:                   $white;
$body-color:                $black;

/* Link Styles */
$link-color:                $green-800;
$link-decoration:           none;
$link-hover-color:          $green-900;
$link-hover-decoration:     null;

:root {
    --svgcolor: #828282;
  }