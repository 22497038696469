.diagonal-shape.bl-to-tr {
  height: 0;
  border-style: solid;
  border-width: 0 0 10vw 100vw;
  border-color: transparent $white $white transparent;
}
.diagonal-shape.tr-to-bl {
  height: 0;
  border-style: solid;
  border-width: 10vw 100vw 0 0;
  border-color: $white transparent transparent $white;
}
.diagonal-shape.tl-to-br {
  height: 0;
  border-style: solid;
  border-width: 0 100vw 10vw 0;
  border-color: transparent transparent $white $white;
}
.diagonal-shape.br-to-tl {
  height: 0;
  border-style: solid;
  border-width: 10vw 0 0 100vw;
  border-color: $white $white transparent transparent;
}

@include media-breakpoint-up(md) { 
  .border-radius-top-left {
    border-top-left-radius: 11rem;
  }
  .border-radius-top-right-md {
    border-top-right-radius: 11rem;
  }
  .border-radius-bottom-left-md {
    border-bottom-left-radius: 11rem;
  }
  .border-radius-bottom-right {
    border-bottom-right-radius: 6rem;
  }
}

@include media-breakpoint-up(xl) { 
  .border-radius-top-right-md {
    border-top-right-radius: 2rem;
  }
  .border-radius-bottom-left-md {
    border-bottom-left-radius: 2rem;
  }
  .border-radius-top-right-xl {
    border-top-right-radius: 11rem;
  }
  .border-radius-bottom-left-xl {
    border-bottom-left-radius: 11rem;
  }
}

.mt-n20 {
  margin-top: -20rem;
}

.mt-20 {
  margin-top: 20rem;
}

.contact-teaser {
  border: 2px solid $black;
  border-top-left-radius: 2rem;
  border-top-right-radius: 11rem;
  border-bottom-left-radius: 11rem;
  border-bottom-right-radius: 2rem;

  .contact-teaser-person {
    .person-image { 
      max-width: 150px;
    }
  }

  @include media-breakpoint-up(xl) { 
    .contact-teaser-person {
      margin: -2px -2px -2px 2px;
      background-color: $white;
      border: 2px solid $black;
      border-top-left-radius: 2rem;
      border-top-right-radius: 11rem;
      border-bottom-left-radius: 11rem;
      border-bottom-right-radius: 2rem;
      .person-image {
        max-width: 350px;
      }
    }
  }
}

/* Contact Divider - just right */
.divider {
  height: 2px;
  width: 100%;
  background-color: $black;
}  
.container-fluid {
  .divider {
    margin-top: -2px;
  }  
}


.hover-shadow {
  transition: box-shadow .2s ease-in-out;
  &:hover {
    transition: box-shadow .2s ease-in-out;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  }
}

.hover-darken {
  transition: filter .2s ease-in-out;
  &:hover {
    transition: filter .2s ease-in-out;
    -webkit-filter: brightness(.8);
    filter: brightness(.8);
  }
}
