/* the popup window */
.cc-window,
.cc-revoke {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box; /* exclude padding when dealing with width */

  font-family: $font-family-monospace;
  font-size: 1rem; /* by setting the base font here, we can size the rest of the popup using CSS `em` */
  line-height: $line-height-base;

  display: flex;
  flex-wrap: nowrap;

  /* the following are random unjustified styles - just because - should probably be removed */
  z-index: 9999;
}

.cc-window.cc-static {
  position: static;
}

/* 2 basic types of window - floating / banner */
.cc-window.cc-floating {
  padding: 2rem;
  max-width: 30rem;
  flex-direction: column;
}
.cc-window.cc-banner {
  padding: 1em 1.8em;
  width: 100%;
  flex-direction: row;
}

.cc-revoke {
  padding: 0.5em;
}
.cc-revoke:hover {
  text-decoration: underline;
}

.cc-header {
  font-size: 18px;
  font-weight: bold;
}

/* clickable things */
.cc-btn,
.cc-link,
.cc-close,
.cc-revoke {
  cursor: pointer;
}

.cc-link {
  opacity: 0.8;
  display: inline-block;
  padding: 0.2em;
  text-decoration: underline;
}
.cc-link:hover {
  opacity: 1;
}
.cc-link:active,
.cc-link:visited {
  color: initial;
}

.cc-btn {
  display: block;
  width: 100%;
  padding: .5rem .75rem;
  font-size: .9375rem;
  line-height: 1.6667;
  font-weight: 400;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.cc-btn:hover,
.cc-btn:active {
  background-color: $green-900;
  border-color: $green-900;
}

.cc-btn.cc-deny {
  background-color: $red !important;
}

.cc-btn.cc-deny:hover,
.cc-btn.cc-deny:active {
  background-color: darken($red, 5%) !important;
  border-color: darken($red, 5%) !important;
}

.cc-close {
  display: block;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 1.6em;
  opacity: 0.9;

  /* seeing as this contains text and not an image, the element taller than it is wide (because it is text) */
  /*  - we want it to be a square, because it's acting as an icon */
  /*  - setting the line height normalises the height */
  line-height: 0.75;
}
.cc-close:hover,
.cc-close:focus {
  opacity: 1;
}

/* This file should contain CSS that modifies the popup layout. */
/* By layout, we mean the physical position of the elements on the popup window, and the margin / padding around those elements. */

.cc-revoke.cc-top {
  top: 0;
  left: 3em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
.cc-revoke.cc-bottom {
  bottom: 0;
  left: 3em;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
.cc-revoke.cc-left {
  left: 3em;
  right: unset;
}
.cc-revoke.cc-right {
  right: 3em;
  left: unset;
}

/**************************************** FLOATING ****************************************/

/* these classes position the floating element */
.cc-top {
  top: 1em;
}
.cc-left {
  left: 1em;
}
.cc-right {
  right: 1em;
}
.cc-bottom {
  bottom: 1em;
}

/* links that are direct decendants should be displayed as block */
.cc-floating > .cc-link {
  margin-bottom: 1em;
}

.cc-floating .cc-message {
  display: block;
  margin-bottom: 1em;

  a {
    color: $green;
  }

  a:hover,
  a:active {
    color: $green-900;
  }
}

.cc-window.cc-floating .cc-compliance {
  flex: 1 0 auto;
}

/**************************************** BANNER ****************************************/

.cc-window.cc-banner {
  align-items: center;
}

.cc-banner.cc-top {
  left: 0;
  right: 0;
  top: 0;
}
.cc-banner.cc-bottom {
  left: 0;
  right: 0;
  bottom: 0;
}

.cc-banner .cc-message {
  display: block;
  flex: 1 1 auto;
  max-width: 100%;
  margin-right: 1em;
}

/* COMPLIANCE BOX */

.cc-compliance {
  display: block;
  align-items: center;
  align-content: space-between;
}
.cc-floating .cc-compliance > .cc-btn {
  flex: 1;
}

.cc-btn + .cc-btn {
  margin-left: 0;
  margin-top: .5rem;
}

@media print {
  .cc-window,
  .cc-revoke {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal;
  }
}

/* dimensions for 'iPhone6 Plus' and lower */
@media screen and (max-width: 414px) and (orientation: portrait),
screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window.cc-top {
    top: 0;
  }
  .cc-window.cc-bottom {
    bottom: 0;
  }
  .cc-window.cc-banner,
  .cc-window.cc-floating,
  .cc-window.cc-right,
  .cc-window.cc-left {
    left: 0;
    right: 0;
  }

  .cc-window.cc-banner {
    flex-direction: column;
  }
  .cc-window.cc-banner .cc-compliance {
    flex: 1 1 auto;
  }
  .cc-window.cc-floating {
    max-width: none;
  }
  .cc-window .cc-message {
    margin-bottom: 1em;
  }
  .cc-window.cc-banner {
    align-items: unset;
  }
  .cc-window.cc-banner .cc-message {
    margin-right: 0;
  }
}

.cc-window {
  opacity: 1;

  -webkit-transition: opacity 1s ease;
  -moz-transition: opacity 1s ease;
  -ms-transition: opacity 1s ease;
  -o-transition: opacity 1s ease;
  transition: opacity 1s ease;
}

.cc-window.cc-invisible {
  opacity: 0;
}

/* only animate ifhas class 'cc-animate' */
.cc-animate.cc-revoke {
  -webkit-transition: transform 1s ease;
  -moz-transition: transform 1s ease;
  -ms-transition: transform 1s ease;
  -o-transition: transform 1s ease;
  transition: transform 1s ease;
}
.cc-animate.cc-revoke.cc-top {
  transform: translateY(-2em);
}
.cc-animate.cc-revoke.cc-bottom {
  transform: translateY(2em);
}
.cc-animate.cc-revoke.cc-active.cc-top {
  transform: translateY(0);
}
.cc-animate.cc-revoke.cc-active.cc-bottom {
  transform: translateY(0);
}
.cc-revoke:hover {
  transform: translateY(0);
}

.cc-grower {
  /* Initially we don't want any height, and we want the contents to be hidden */
  max-height: 0;
  overflow: hidden;

  /* Set our transitions up. */
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
}